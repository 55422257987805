import { Col, Row, Button } from 'react-bootstrap';
import Logo from './Logo';
import { useState } from "react";
import '../App.css'
import DateTimePicker from 'react-datetime-picker';
import { teamColors, teamColorsSecondary, teamColorsFamily, isAusnahme, getBrightness, } from './GamePanel';
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

function GameCreatePanel(props: {
    smallScreen: boolean,
    createGame: (gameTime: Date, homeTeam: string, awayTeam: string) => void
}) {
    const [homeTeam, setHomeTeam] = useState("New Orleans Saints");
    const [awayTeam, setAwayTeam] = useState("Los Angeles Chargers");
    const [gameTime, setGameTime] = useState(new Date());
    const updateGT = (event: any) => setGameTime(event);
    let homeColor = teamColors[homeTeam];
    // special special case
    if (homeTeam === "Cleveland Browns" && awayTeam === "Chicago Bears") {
        homeColor = teamColorsSecondary[homeTeam];
    }
    if (awayTeam === "Raiders Tirol" && teamColorsFamily[homeTeam] === teamColorsFamily[awayTeam]) {
        homeColor = teamColorsSecondary[homeTeam];
    }
    let awayColor = teamColors[awayTeam];
    if (teamColorsFamily[homeTeam] === teamColorsFamily[awayTeam] || isAusnahme(homeTeam, awayTeam)) {
        awayColor = teamColorsSecondary[awayTeam];
    }

    let awayFontColor = getBrightness(awayColor) > 127 ? "black" : "white";
    let homeFontColor = getBrightness(homeColor) > 127 ? "black" : "white";

    let border = { borderStyle: "solid", borderColor: "black", borderWidth: "3px", borderRadius: "10px" }

    const smallScreenHeight = "75px"

    return (
        <div style={{ marginBottom: "300px" }}>
            <Row>
                <Col>
                    <DateTimePicker
                        onChange={updateGT}
                        value={gameTime}
                    />
                </Col>
            </Row>
            <Row style={{ ...border, alignItems: "stretch", marginTop: "5px" }}>
                <Col className="GamePanel" style={{
                    float: "left",
                    backgroundColor: awayColor,
                    color: awayFontColor,
                    fontSize: "22px",
                    marginRight: props.smallScreen ? "" : "-15px",
                    //clipPath: props.smallScreen ? "" : "polygon(0 0, 100% 0%, 95% 100%, 0% 100%)"
                }}>
                    <Row>
                        <Col md="4"><Logo
                            gray={false}
                            teamName={awayTeam}
                            float={props.smallScreen ? "left" : null}
                            height={props.smallScreen ? smallScreenHeight : "100px"}
                        /></Col>
                        <Col style={{ alignSelf: "center" }}>
                            <ReactSearchAutocomplete
                                items={Object.keys(teamColors).map((x, i) => { return { name: x, id: i } })}
                                onSelect={(selected) => setAwayTeam(selected.name)}
                                inputSearchString={awayTeam}
                                showIcon={false}
                            />
                        </Col>
                        <Col md="2"></Col>
                    </Row>
                </Col>
                <Col className="GamePanel" style={{
                    float: "right",
                    backgroundColor: homeColor,
                    marginLeft: props.smallScreen ? "" : "-15px",
                    color: homeFontColor,
                    fontSize: "22px",
                    //clipPath: props.smallScreen ? "" : "polygon(5% 0, 100% 0%, 100% 100%, 0% 100%)"
                }}>
                    <Row>
                        <Col md="4"><Logo
                            gray={false}
                            teamName={homeTeam}
                            float={props.smallScreen ? "right" : null}
                            height={props.smallScreen ? smallScreenHeight : "100px"}
                        /></Col>
                        <Col style={{ alignSelf: "center" }}>
                            <ReactSearchAutocomplete
                                items={Object.keys(teamColors).map((x, i) => { return { name: x, id: i } })}
                                onSelect={(selected) => setHomeTeam(selected.name)}
                                inputSearchString={homeTeam}
                                showIcon={false}
                            />
                        </Col>
                        <Col md="2"></Col>
                    </Row>
                    <Row>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Button variant="primary" type="submit" onClick={(event) => {
                    event.preventDefault();
                    props.createGame(gameTime, homeTeam, awayTeam);
                }}>
                    Create
                </Button>
            </Row>
        </div>
    )
}

export default GameCreatePanel;