import { Container } from "react-bootstrap";
import { Game } from "../util/types";
import { useViewport } from "../util/Viewport";
import GamePanel from "./GamePanel";

export default function GamesComponent(props: {
  games: Array<Game>,
  error: string | null,
  isLoaded: boolean,
  bets: { [gameId: number]: boolean },
  survivorMode: boolean,
  survivor: any,
  makeBet: (gameId: number, betHomeWins: boolean, survivorPick: boolean) => void,
  record: { [teamId: number]: Array<number> },
}) {
  const { width } = useViewport();
  const survivorUsed = function (teamName: string) {
    return props.survivor.indexOf(teamName) >= 0;
  }
  if (props.error) {
    return <div>Error: {props.error}</div>;
  } else if (!props.isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Container>
        {props.games.sort((a, b) => Date.parse(a.gameTime) - Date.parse(b.gameTime)).map((item, index) => (
          <div key={index}>
            {(index === 0 || item.gameTime !== props.games[index - 1].gameTime) ? <h2>{
              new Intl.DateTimeFormat('de', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }).format(Date.parse(item.gameTime))
            }</h2> : ""}
            <GamePanel
              key={item.id}
              gameId={item.id}
              homeTeam={item.homeTeam.teamName}
              homeTeamRecord={props.record[item.homeTeam.id]}
              awayTeamRecord={props.record[item.awayTeam.id]}
              awayTeam={item.awayTeam.teamName}
              homeScore={item.homeScore}
              awayScore={item.awayScore}
              betHomeWins={props.survivorMode ? undefined : props.bets[item.id]}
              survivorPick={props.survivorMode}
              makeBet={props.makeBet}
              smallScreen={width < 1000}
              homeSurvivorUsed={survivorUsed(item.homeTeam.teamName)}
              awaySurvivorUsed={survivorUsed(item.awayTeam.teamName)}
            />
          </div>
        ))}
      </Container>
    );
  }
}