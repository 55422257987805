import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Container, Row, Table } from "react-bootstrap";
import { apiBase } from "../App";
import { Bet, GameResults, League } from "../util/types";
import wolf from '../img/wolf.png';
import esel from '../img/esel.png';
import heart from '../img/heart.png';
import broken_heart from '../img/broken heart.png';
import reaper from '../img/reaper.png';

// gameResults not used so far, can be used to make pick based on standing or winstreak
const betsToStandings = (bets: Array<Bet>, gameResults: GameResults) => {
  let betsByGame: { [id: number]: Array<Bet> } = {};
  let scoreByUser: { [name: string]: { total: number, correct: number, lonely: number, donkey: number } } = { "Home Boy": { total: 0, correct: 0, lonely: 0, donkey: 0 } };
  for (let b of bets) {
    if (!(b.game.id in betsByGame)) {
      betsByGame[b.game.id] = [];
    }
    if (!(b.user.userName in scoreByUser)) {
      scoreByUser[b.user.userName] = { total: 0, correct: 0, lonely: 0, donkey: 0 }
    }
    betsByGame[b.game.id].push(b);
  }
  for (const betsForDay of Object.values(betsByGame)) {
    let numCorrect = 0;
    let numIncorrect = 0;
    let correctUser = "";
    let incorrectUser = "";
    let homeWon: boolean | null = null;
    for (let b of betsForDay) {
      if (b.game.homeScore !== null && b.game.awayScore !== null) {
        scoreByUser[b.user.userName].total += 1;
        homeWon = b.game.homeScore >= b.game.awayScore;
        let isCorrect = ((b.betHomeWins && homeWon) || (!b.betHomeWins && b.game.homeScore <= b.game.awayScore));
        if (isCorrect) {
          scoreByUser[b.user.userName].correct += 1;
          numCorrect += 1;
          correctUser = b.user.userName;
        } else {
          numIncorrect += 1;
          incorrectUser = b.user.userName;
        }
      }
    }
    if (numCorrect === 1) {
      scoreByUser[correctUser].lonely += 1;
    }
    if (numIncorrect === 1) {
      scoreByUser[incorrectUser].donkey += 1;
    }
    if (homeWon !== null) {
      scoreByUser["Home Boy"].total += 1;
      scoreByUser["Home Boy"].correct += (homeWon ? 1 : 0);
    }
  }
  return scoreByUser;
}

export default function LeaderboardComponent(props: { season: number, gameResults: GameResults, league: League, survivorByPlayerAndWeek: any}) {
  const [allBets, setAllBets] = useState<Bet[] | null>(null);
  const [leagueBets, setLeagueBets] = useState<Bet[] | null>(null);
  useEffect(() => {
    fetch(`${apiBase}/bets/${props.season}/all`)
      .then(res => res.json())
      .then(
        (result) => {
          setAllBets(result.bets)
          setLeagueBets(result.bets.filter((x: Bet) => x.game.league === props.league));
          //console.log(result.bets)
        },
        (error) => {
          console.log(error);
        }
      )
  }, [props.season])
  useEffect(() => {
    if(allBets){
      setLeagueBets(allBets.filter(x => x.game.league === props.league));
    }
  }, [props.league])
  let standingList = [<Row></Row>]
  if (leagueBets !== null) {
    let scoreByUser = betsToStandings(leagueBets, props.gameResults);
    standingList = Object.keys(scoreByUser).sort((b, a) => scoreByUser[a].correct - scoreByUser[b].correct).map(
      key => (<tr key={key}>
        <td key={key + "_name"}>{key === "Home Boy" ? <div><FontAwesomeIcon icon={faRobot} /> {key}</div> : key}</td>
        <td key={key + "_score"}>{scoreByUser[key].correct}</td>
        <td key={key + "_total"}>{scoreByUser[key].total}</td>
        <td key={key + "_percent"}>{Math.round(1000 * scoreByUser[key].correct / scoreByUser[key].total) / 10 + " %"}</td>
        <td key={key + "_lonely"}>{scoreByUser[key].lonely}</td>
        <td key={key + "_donkey"}>{scoreByUser[key].donkey}</td>
      </tr>)
    );
  }
  return (
    <Container>
      <h3>Pick'em</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Spieler</th>
            <th>Punkte</th>
            <th>Tipps</th>
            <th>Quote</th>
            <th><img src={wolf} height="50px" alt="lonely wolf" /></th>
            <th><img src={esel} height="50px" alt="lonely donkey" /></th>
          </tr>
        </thead>
        <tbody>
          {standingList}
        </tbody>
      </Table>
      <h3>Survivor</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Spieler</th>
            <th>Status</th>
            <th>Verlauf</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(props.survivorByPlayerAndWeek).sort((a,b)=>props.survivorByPlayerAndWeek[b].lives-props.survivorByPlayerAndWeek[a].lives || props.survivorByPlayerAndWeek[b].diedInWeek-props.survivorByPlayerAndWeek[a].diedInWeek).map(
            key => (<tr key={key}>
              <td key={key + "_name"}>{key}</td>
              <td key={key + "_status"}>{props.survivorByPlayerAndWeek[key].alive ? props.survivorByPlayerAndWeek[key].lives : <div><img src={reaper} width="25em"/> ({props.survivorByPlayerAndWeek[key].diedInWeek})</div>}</td>
              <td key={key + "_results"} align="left">{props.survivorByPlayerAndWeek[key].results.map((x: any) => (x.alive && x.gameOver) ? (<img src={x.correct ? heart : broken_heart} width="25em" style={{marginRight: "2px"}}/>) : "")}</td>
            </tr>)
          
          )}
        </tbody>
      </Table>
    </Container>
  )
}
