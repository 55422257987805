import { faSignInAlt, faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapValues, uniq } from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Container, Modal, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { Route, Switch, useHistory } from 'react-router-dom';
import './App.css';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import ChangePWComponent from './components/ChangePWComponent';
import ComparisonComponent from './components/ComparisonComponent';
import GamesComponent from './components/GamesComponent';
import GamesUpdateComponent from './components/GamesUpdateComponent';
import LeaderboardComponent from './components/LeaderboardComponent';
import LoginComponent from './components/LoginComponent';
import StatsComponent from './components/StatsComponent';
import { Game, GameResults, League, SurvivorWeekResult, } from './util/types';
import nflLogo from "./img/logos/NFL.png"
import elfLogo from "./img/logos/European League of Football.png"
import pickemLogo from "./img/pickem_logo.png"
import SurvivorPick from './components/SurvivorPick';

export const apiBase = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://api.pickem.ankenbrand.me";
//export const apiBase = "http://localhost:3000";

function App() {
  const [games, setGames]: [Array<Game>, any] = useState([]);
  const [gameDay, setGameDay]: [number, any] = useState(-1);
  const [season, setSeason]: [number, any] = useState(2023);
  const [league, setLeague]: [League, any] = useState("elf");
  const [bets, setBets]: [{ [gameId: number]: boolean }, any] = useState({});
  const [survivor, setSurvivor]: [Array<any>, any] = useState([]);
  const [error, setError]: [string | null, any] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser]: [{ name: string, token: string }, any] = useState({ name: "", token: "" });
  const [gameResults, setGameResults]: [GameResults, any] = useState({})
  const [tooLate, setTooLate] = useState(false)
  const [survivorProblem, setSurvivorProblem] = useState(false)

  const refreshUser = () => {
    const user_value = localStorage.getItem('user');
    if (user_value) {
      const localstorage_user = JSON.parse(user_value)
      setUser({ name: localstorage_user.user, token: localstorage_user.token })
    }
  }

  const makeBet = (gameId: number, homeWins: boolean, survivorPick: boolean) => {
    if (user && user.token) {
      //check if it is too late
      let game = games.find(x => x.id === gameId);
      if(!game){
        return;
      }
      if (new Date(Date.parse(game.gameTime)) < new Date()) {
        setTooLate(true)
      } else {
        if(!survivorPick){
          // make pickem pick
          fetch(apiBase + "/bets/makeBet?" + new URLSearchParams({
            gameId: gameId.toString(),
            betHomeWins: homeWins.toString()
          }), {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          })
            .then(res => res.json())
            .then(
              (result) => {
                getBets();
              },
              (error) => {
                console.log(error);
              }
            )
        } else {
          // make survivor pick
          fetch(apiBase + "/survivor/makeBet?" + new URLSearchParams({
            league: league,
            season: season.toString(),
            gameDay: gameDay.toString(),
            teamId: homeWins ? game.homeTeam.id.toString() : game.awayTeam.id.toString()
          }), {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + user.token
            }
          })
            .then(res => res.json())
            .then(
              (result) => {
                if(result.error){
                  setSurvivorProblem(true);
                  return;
                }
                else {
                  getSurvivor();
                }
              },
              (error) => {
                setSurvivorProblem(true);
                console.log(error);
              }
            )
        }
      }
    } else {
      history.push("/login")
    }
  }

  const removeSurvivor = () => {
    if (user && user.token) {
      fetch(apiBase + "/survivor/removeBet?" + new URLSearchParams({
        league: league,
        season: season.toString(),
        gameDay: gameDay.toString(),
      }), {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.error){
              setTooLate(true);
              return;
            }
            else {
              getSurvivor();
            }
          },
          (error) => {
            setTooLate(true);
            console.log(error);
          }
        )
    }
  }

  const createGame = (gameTime: Date, homeTeam: string, awayTeam: string) => {
    if (user && user.token) {
      // type is object with string keys and string values
      let urlParams: Record<string, string> = {
        gameTime: gameTime.toISOString(),
        homeTeam: homeTeam,
        awayTeam: awayTeam,
        league: league,
        season: season.toString(),
        gameDay: gameDay.toString()
      };
      fetch(apiBase + "/games/create_game?" + new URLSearchParams(urlParams), {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            getGamesFromAPI();
          },
          (error) => {
            console.log(error);
          }
        )
    } else {
      history.push("/login")
    }
  }

  const updateGame = (gameId: number, gameTime: Date, awayScore: number | null, homeScore: number | null) => {
    if (user && user.token) {
      // type is object with string keys and string values
      let urlParams: Record<string, string> = {
        gameId: gameId.toString(),
        gameTime: gameTime.toISOString()
      };
      if (awayScore !== null) {
        urlParams["awayScore"] = awayScore.toString();
      }
      if (homeScore !== null) {
        urlParams["homeScore"] = homeScore.toString();
      }
      fetch(apiBase + "/games/update_game?" + new URLSearchParams(urlParams), {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            //getBets();
          },
          (error) => {
            console.log(error);
          }
        )
    } else {
      history.push("/login")
    }
  }

  const getBets = () => {
    if (user && user.token) {
      fetch(`${apiBase}/bets/${season}/user`, {
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then(res => res.json())
        .then(
          (result: { bets: [{ betHomeWins: boolean, game: { id: number } }] }) => {
            let betDict: { [key: number]: boolean } = {};
            if (result.bets) {
              result.bets.map(x => betDict[x.game.id] = x.betHomeWins)
            }
            setBets(betDict);
          },
          (error) => {
            console.log(error);
          }
        )
    } else {
      setBets({})
    }
  }

  const getSurvivor = () => {
    if (user && user.token) {
      fetch(`${apiBase}/survivor/${season}/user`, {
        headers: {
          'Authorization': 'Bearer ' + user.token
        }
      })
        .then(res => res.json())
        .then(
          (result => {
            if (result.survivor) {
              setSurvivor(result.survivor);
            }
          }),
          (error) => {
            console.log(error);
          }
        )
    } else {
      setSurvivor([])
    }
  }

  useEffect(() => {
    refreshUser()
  }, [])

  useEffect(() => {
    fetch(apiBase + "/games/gameDay")
      .then(res => res.json())
      .then(
        (result) => {
          setLeague(result.result.league);
          setSeason(result.result.season);
          setGameDay(result.result.gameDay);
          setIsLoaded(true);
        },
        (error) => {
          setIsLoaded(true);
          console.log(error);
          setError("There was an Error calling the API");
        }
      )
  }, [])

  useEffect(() => {
    fetch(`${apiBase}/games/${league}/${season}/results`)
      .then(res => res.json())
      .then(
        (result) => {
          setGameResults(result.results);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [season, league])

  useEffect(() => {
    getBets();
    getSurvivor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, season])

  const getGamesFromAPI = () => {
    fetch(`${apiBase}/games/${league}/${season}/day/${gameDay}`)
      .then(res => res.json())
      .then(
        (result) => {
          setGames(result.games)
        },
        (error) => {
          console.log(error);
        }
      )
  }

  const usedSurvivors = survivor.filter(x => x.user.userName === user.name && season === x.season && league === x.league && gameDay != x.gameDay).map(x => x.team.teamName)

  const maxWeekWithResult = Math.max(...Object.values(gameResults).map(x=>x.map(y=>y[0])).map(x=>Math.max(...x)))
  const survivorPlayers = Object.fromEntries(uniq(survivor.map(x => x.user.userName)).map(x=>[x,{}]))
  const maxWeekSurvivor = league === "elf" ? 14 : 18
  const emptyTeam = {teamName: "", id: -1}
  const survivorByPlayerAndWeek = mapValues(survivorPlayers, (x, name) => {
    let results = Array.from({length: maxWeekSurvivor}, (): SurvivorWeekResult => {return {"team": emptyTeam, "correct": undefined, "gameOver": false, alive: true}});
    let survivorForPlayer = survivor.filter(y => y.user.userName === name && y.season == season && y.league === league);
    for(const s of survivorForPlayer){
      let team = s.team;
      let gameOver = typeof s.game.homeScore === "number" && typeof s.game.awayScore === "number";
      let correct = undefined;
      if(gameOver){
        correct = (s.game.homeScore >= s.game.awayScore && s.game.homeTeamId === team.id) || (s.game.awayScore >= s.game.homeScore && s.game.awayTeamId === team.id);
      }
      results[s.gameDay-1] = {"team": s.team, "correct": correct, gameOver: gameOver, alive: true};
    }
    let lives = 3;
    let alive = true;
    let diedInWeek = -1;
    for(let week=0; week<maxWeekSurvivor; week++){
      if(typeof results[week].correct === "undefined" && week<maxWeekWithResult-1){
        results[week] = {team: emptyTeam, correct: false, gameOver: true, alive: true};
      }
      if(!alive){
        results[week] = {team: emptyTeam, correct: undefined, gameOver: results[week].gameOver, alive: false};
      }
      if(results[week].correct === false){
        lives -= 1;
        if(lives === 0){
          alive = false;
          diedInWeek = week+1;
        }
      }
    }
    return {results: results, lives: lives, alive: alive, diedInWeek: diedInWeek};
  })

  useEffect(getGamesFromAPI, [gameDay, season, league])
  const history = useHistory()
  let record = mapValues(gameResults, (res: Array<[number, number]>) => {
    let rec = [0, 0, 0]
    for (let r of res) {
      if (r[0] <= gameDay) {
        rec[r[1]] += 1;
      }
    }
    return rec
  });

  const gameDaysNflOld = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","Wild Card","Divisional","Conference Championship","Superbowl"]
  const gameDaysNflNew = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","Wild Card","Divisional","Conference Championship","Superbowl"]
  const gameDaysElf21 = ["1","2","3","4","5","6","7","8","9","10","11","12","Semi Final","Championship Game"]
  const gameDaysElf22 = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","Semi Final","Championship Game"]
  const gameDaysElf = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","Wild Card","Semi Final","Championship Game"]
  let relevantGameDays = gameDaysNflNew;
  if((league as League) === "nfl" && season <= 2020){
    relevantGameDays = gameDaysNflOld;
  }
  if((league as League) === "elf"){
    relevantGameDays = gameDaysElf;
    if(season == 2021){
      relevantGameDays = gameDaysElf21;
    }
    if(season == 2022){
      relevantGameDays = gameDaysElf22;
    }
  }
  let gameDays = relevantGameDays.map((x, i) => <NavDropdown.Item key={i} eventKey={`${i + 1}`}>{x}</NavDropdown.Item>)

  return (
    <div className="App">
      <Navbar expand="lg" bg="dark" variant="dark">
        <Navbar.Brand href="/"><img src={pickemLogo} height={45}/></Navbar.Brand>
        <NavItem>
          <a href="#"><img src={nflLogo} style={(league as League) === "nfl" ? {} : { filter: "grayscale(100%)" }} alt="NFL" height={50} onClick={() => setLeague("nfl")} /></a>
          <a href="#"><img src={elfLogo} style={(league as League) === "elf" ? {} : { filter: "grayscale(100%)" }} alt="ELF" height={38} onClick={() => setLeague("elf")} /></a>
        </NavItem>
        <NavDropdown title={"Saison: " + season} id="nav-dropdown" onSelect={(eventKey) => {
          //history.push("/")
          setSeason(eventKey)
        }}>
          <NavDropdown.Item eventKey="2010">2010</NavDropdown.Item>
          <NavDropdown.Item eventKey="2011">2011</NavDropdown.Item>
          <NavDropdown.Item eventKey="2012">2012</NavDropdown.Item>
          <NavDropdown.Item eventKey="2013">2013</NavDropdown.Item>
          <NavDropdown.Item eventKey="2014">2014</NavDropdown.Item>
          <NavDropdown.Item eventKey="2015">2015</NavDropdown.Item>
          <NavDropdown.Item eventKey="2016">2016</NavDropdown.Item>
          <NavDropdown.Item eventKey="2017">2017</NavDropdown.Item>
          <NavDropdown.Item eventKey="2018">2018</NavDropdown.Item>
          <NavDropdown.Item eventKey="2019">2019</NavDropdown.Item>
          <NavDropdown.Item eventKey="2020">2020</NavDropdown.Item>
          <NavDropdown.Item eventKey="2021">2021</NavDropdown.Item>
          <NavDropdown.Item eventKey="2022">2022</NavDropdown.Item>
          <NavDropdown.Item eventKey="2023">2023</NavDropdown.Item>
          <NavDropdown.Item eventKey="2024">2024</NavDropdown.Item>
        </NavDropdown>
        <NavDropdown title={"Spieltag: " + gameDay} id="nav-dropdown" onSelect={(eventKey) => {
          //history.push("/")
          setGameDay(eventKey)
        }}>
          {gameDays}
        </NavDropdown>
        <Nav.Link onClick={() => history.push("/")}>Picks</Nav.Link>
        <Nav.Link onClick={() => history.push("/survivor")}>Survivor</Nav.Link>
        <Nav.Link onClick={() => history.push("/leaderboard")}>Leaderboard</Nav.Link>
        <Nav.Link onClick={() => history.push("/comp")}>Vergleich</Nav.Link>
        <Nav.Link onClick={() => history.push("/stats")}>Statistiken</Nav.Link>
        <Nav.Link onClick={() => history.push("/admin")} className="ml-auto">Admin</Nav.Link>
        {user.name ?
          <Nav.Link onClick={() => { localStorage.removeItem('user'); setUser({ name: "", token: "" }) }} className="ml-auto" title="Logout"><FontAwesomeIcon icon={faUserCircle} /> {user.name} <FontAwesomeIcon icon={faSignOutAlt} /></Nav.Link> :
          <Nav.Link onClick={() => history.push("/login")} className="ml-auto"><FontAwesomeIcon icon={faSignInAlt} /></Nav.Link>}
      </Navbar>
      {!tooLate ? "" : (<Modal show={tooLate} onHide={() => setTooLate(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Zu spät!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Dieses Spiel hat schon begonnen! Daher kannst du deinen Tipp nicht mehr ändern!</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setTooLate(false)}>
            Seh' ich ein
          </Button>
        </Modal.Footer>
      </Modal>)}
      {!survivorProblem ? "" : (<Modal show={survivorProblem} onHide={() => setSurvivorProblem(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Survivor Problem!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Es ist nicht möglich, diesen Survivor Pick zu machen. Wahrscheinlich hast du das Team in dieser Saison bereits als Survivor gewählt.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setSurvivorProblem(false)}>
            Okay, danke
          </Button>
        </Modal.Footer>
      </Modal>)}
      <Container style={{overflow: "scroll"}}>
      <Switch>
        <Route exact path="/">
          <GamesComponent games={games} error={error} isLoaded={isLoaded} bets={bets} survivorMode={false} makeBet={makeBet} record={record} survivor={usedSurvivors}/>
        </Route>
        <Route exact path="/survivor">
          {
            isNaN(parseInt(relevantGameDays[gameDay - 1])) ?
            <div>Survivor gibt es nur in der Regular Season</div> :
            <div>
              Survivor of the day: <SurvivorPick survivorByPlayerAndWeek={survivorByPlayerAndWeek} gameDay={gameDay} league={league} userName={user.name || ""} season={season} removeSurvivor={removeSurvivor}/>
              <GamesComponent games={games} error={error} isLoaded={isLoaded} bets={bets} survivorMode={true} makeBet={makeBet} record={record} survivor={usedSurvivors}/>
            </div>
          }
        </Route>
        <Route exact path="/admin">
          <GamesUpdateComponent games={games} error={error} isLoaded={isLoaded} bets={bets} updateGame={updateGame} createGame={createGame} record={record} />
        </Route>
        <Route path="/login">
          <LoginComponent afterLogin={refreshUser} />
        </Route>
        <Route path="/change_password">
          <ChangePWComponent user={user} />
        </Route>
        <Route path="/leaderboard">
          <LeaderboardComponent season={season} gameResults={gameResults} league={league} survivorByPlayerAndWeek={survivorByPlayerAndWeek} />
        </Route>
        <Route path="/comp">
          <ComparisonComponent season={season} games={games} gameDay={gameDay} user={user} survivorByPlayerAndWeek={survivorByPlayerAndWeek}/>
        </Route>
        <Route path="/stats">
          <StatsComponent season={season} league={league}/>
        </Route>
      </Switch>
      </Container>
    </div>
  );
}

export default App;
