import { Button, Container } from "react-bootstrap";
import { Game } from "../util/types";
import { useViewport } from "../util/Viewport";
import GameUpdatePanel from "./GameUpdatePanel";
import GameCreatePanel from "./GameCreatePanel";
import { useState } from "react";

export default function GamesComponent(props: {
  games: Array<Game>,
  error: string | null,
  isLoaded: boolean,
  bets: { [gameId: number]: boolean },
  updateGame: (gameId: number, gameTime: Date, awayScore: number | null, homeScore: number | null) => void,
  createGame: (gameTime: Date, homeTeam: string, awayTeam: string) => void,
  record: { [teamId: number]: Array<number> }
}) {
  const { width } = useViewport();
  const [showCreateGame, setShowCreateGame] = useState(false);
  if (props.error) {
    return <div>Error: {props.error}</div>;
  } else if (!props.isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Container>
        {props.games.sort((a, b) => Date.parse(a.gameTime) - Date.parse(b.gameTime)).map((item, index) => (
          <div>
            {(index === 0 || item.gameTime !== props.games[index - 1].gameTime) ? <h2>{
              new Intl.DateTimeFormat('de', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric'
              }).format(Date.parse(item.gameTime))
            }</h2> : ""}
            <GameUpdatePanel
              key={item.id}
              gameId={item.id}
              gameTime={item.gameTime}
              homeTeam={item.homeTeam.teamName}
              homeTeamRecord={props.record[item.homeTeam.id]}
              awayTeamRecord={props.record[item.awayTeam.id]}
              awayTeam={item.awayTeam.teamName}
              homeScore={item.homeScore}
              awayScore={item.awayScore}
              betHomeWins={props.bets[item.id]}
              updateGame={props.updateGame}
              smallScreen={width < 1000}
            />
          </div>
        ))}
        <h2>
          <Button onClick={() => setShowCreateGame(true)}>
            Neues Spiel erstellen
          </Button>
        </h2>
        <div style={{display: showCreateGame ? "block" : "none"}}>
          <GameCreatePanel
            smallScreen={width < 1000}
            createGame={props.createGame}
          />
        </div>
      </Container>
    );
  }
}