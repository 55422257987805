import { Col, Container, Row } from "react-bootstrap";
import Logo from "./Logo";
import heart from "../img/heart.png";
import broken_heart from "../img/broken heart.png";
import reaper from "../img/reaper.png";
import { getBrightness, grayColor, teamColors, teamColorsSecondary } from "./GamePanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function SurvivorPick(props: {
  survivorByPlayerAndWeek: { [userName: string]: { results: Array<any>, alive: boolean, lives: number } },
  userName: string,
  gameDay: number,
  league: string,
  season: number,
  removeSurvivor: () => void,
}) {
  const noTeamSelected = "Du hast kein Team ausgewählt"
  const resultOfTheDay = () => {
    let survivorForPlayer = props.survivorByPlayerAndWeek[props.userName];
    if (survivorForPlayer && survivorForPlayer.results.length >= props.gameDay - 1) {
      return survivorForPlayer.results[props.gameDay - 1];
    }
    return { team: { teamName: "" }, correct: true, alive: true, gameOver: false };
  }
  const rod = resultOfTheDay();
  let sod = rod.team;
  let bgColor = rod.correct === false ? grayColor : teamColors[sod.teamName] || "#ffffff";
  let borderColor = teamColorsSecondary[sod.teamName] || "black";
  let fontColor = getBrightness(bgColor) > 127 ? "black" : "white";
  return (
    <Container style={{ marginBottom: "3em" }}>
      <Row style={{ alignItems: "stretch", marginTop: "5px" }}>
        <Col md="3" style={{ textAlign: "center" }}></Col>
        <Col className={`GamePanel`} style={{ // ${awayBackground}`} style={{
          float: "left",
          backgroundColor: bgColor,
          color: fontColor,
          fontSize: "22px",
          borderRadius: "10px",
          border: `4px solid ${borderColor}`,
        }}>
          <Row style={{ position: "relative" }}>
            {sod.teamName === "" ?
              (rod.alive ? <Col md="2"></Col> : <Col md="4"><img src={reaper} height="100em"></img></Col>) :
              <Col md="4"><Logo
                gray={rod.correct === false}
                teamName={sod.teamName}
                //float={props.smallScreen ? "left" : null}
                height={"100px"}

              /></Col>}
            <Col style={{ alignSelf: "center" }}>{sod.teamName || (rod.alive ? noTeamSelected : "ausgeschieden")}</Col>
            <Col md="2" style={{ alignSelf: "center" }}>{
              rod.correct ?
                <img src={heart} height="50em" /> :
                (
                  rod.gameOver && rod.alive ?
                    <img src={broken_heart} height="50em" /> :
                    ""
                )
            }</Col>
            {!rod.gameOver && sod.teamName ? <FontAwesomeIcon icon={faCircleXmark} onClick={props.removeSurvivor} style={{ position: "absolute", right: 10, top: 10 }} /> : ""}
          </Row>
        </Col>
        <Col md="3" style={{ textAlign: "center" }}></Col>
      </Row>
    </Container>
  );
}