import cardinals from '../img/logos/Arizona Cardinals.png';
import falcons from '../img/logos/Atlanta Falcons.png';
import ravens from '../img/logos/Baltimore Ravens.png';
import bills from '../img/logos/Buffalo Bills.png';
import panthers from '../img/logos/Carolina Panthers.png';
import bears from '../img/logos/Chicago Bears.png';
import bengals from '../img/logos/Cincinnati Bengals.png';
import browns from '../img/logos/Cleveland Browns.png';
import cowboys from '../img/logos/Dallas Cowboys.png';
import broncos from '../img/logos/Denver Broncos.png';
import lions from '../img/logos/Detroit Lions.png';
import packers from '../img/logos/Green Bay Packers.png';
import texans from '../img/logos/Houston Texans.png';
import colts from '../img/logos/Indianapolis Colts.png';
import jaguars from '../img/logos/Jacksonville Jaguars.png';
import chiefs from '../img/logos/Kansas City Chiefs.png';
import raiders from '../img/logos/Las Vegas Raiders.png';
import raiders2 from '../img/logos/Las Vegas Raiders2.png';
import chargers from '../img/logos/Los Angeles Chargers.png';
import rams from '../img/logos/Los Angeles Rams.png';
import dolphins from '../img/logos/Miami Dolphins.png';
import vikings from '../img/logos/Minnesota Vikings.png';
import patriots from '../img/logos/New England Patriots.png';
import saints from '../img/logos/New Orleans Saints.png';
import giants from '../img/logos/New York Giants.png';
import jets from '../img/logos/New York Jets.png';
import eagles from '../img/logos/Philadelphia Eagles.png';
import steelers from '../img/logos/Pittsburgh Steelers.png';
import sf49ers from '../img/logos/San Francisco 49ers.png';
import seahawks from '../img/logos/Seattle Seahawks.png';
import buccaneers from '../img/logos/Tampa Bay Buccaneers.png';
import titans from '../img/logos/Tennessee Titans.png';
import washington from '../img/logos/Washington.png';
import washington2 from '../img/logos/Washington Commanders.png';
import barcelona from '../img/logos/Barcelona Dragons.png';
import berlin from '../img/logos/Berlin Thunder.png';
import cologne from '../img/logos/Cologne Centurions.png';
import fehervar from '../img/logos/Fehérvár Enthroners.png';
import frankfurt from '../img/logos/Frankfurt Galaxy.png';
import hamburg from '../img/logos/Hamburg Sea Devils.png';
import helvetic from '../img/logos/Helvetic Guards.png';
import leipzig from '../img/logos/Leipzig Kings.png';
import milano from '../img/logos/Milano Seamen.png';
import munich from '../img/logos/Munic Ravens.png';
import bresslau from '../img/logos/Panthers Wroclaw.png';
import paris from '../img/logos/Paris Saints.png';
import prag from '../img/logos/Prague Lions.png';
import tirol from '../img/logos/Raiders Tirol.png';
import rhein from '../img/logos/Rhein Fire.png';
import stuttgart from '../img/logos/Stuttgart Surge.png';
import vienna from '../img/logos/Vienna Vikings 2.png';
import viennaAlt from '../img/logos/Vienna Vikings 3.png';
import paris2 from '../img/logos/Paris Saints 3.png';
import madrid from '../img/logos/Madrid Bravos.png';
import istanbul from '../img/logos/Istanbul Rams.png';


const nameToImg: { [name: string]: string } = {
    "Arizona Cardinals": cardinals,
    "Atlanta Falcons": falcons,
    "Baltimore Ravens": ravens,
    "Buffalo Bills": bills,
    "Carolina Panthers": panthers,
    "Chicago Bears": bears,
    "Cincinnati Bengals": bengals,
    "Cleveland Browns": browns,
    "Dallas Cowboys": cowboys,
    "Denver Broncos": broncos,
    "Detroit Lions": lions,
    "Green Bay Packers": packers,
    "Houston Texans": texans,
    "Indianapolis Colts": colts,
    "Jacksonville Jaguars": jaguars,
    "Kansas City Chiefs": chiefs,
    "Las Vegas Raiders": raiders,
    "Los Angeles Chargers": chargers,
    "Los Angeles Rams": rams,
    "Miami Dolphins": dolphins,
    "Minnesota Vikings": vikings,
    "New England Patriots": patriots,
    "New Orleans Saints": saints,
    "New York Giants": giants,
    "New York Jets": jets,
    "Philadelphia Eagles": eagles,
    "Pittsburgh Steelers": steelers,
    "San Francisco 49ers": sf49ers,
    "Seattle Seahawks": seahawks,
    "Tampa Bay Buccaneers": buccaneers,
    "Tennessee Titans": titans,
    "Washington": washington,
    "Washington Commanders": washington2,
    "Barcelona Dragons": barcelona,
    "Berlin Thunder": berlin,
    "Cologne Centurions": cologne,
    "Fehérvár Enthroners": fehervar,
    "Frankfurt Galaxy": frankfurt,
    "Hamburg Sea Devils": hamburg,
    "Helvetic Mercenaries": helvetic,
    "Leipzig Kings": leipzig,
    "Milano Seamen": milano,
    "Munich Ravens": munich,
    "Panthers Wrocław": bresslau,
    "Paris Musketeers": paris,
    "Prague Lions": prag,
    "Raiders Tirol": tirol,
    "Rhein Fire": rhein,
    "Stuttgart Surge": stuttgart,
    "Vienna Vikings": vienna,
    "Vienna Vikings (alternate)": viennaAlt,
    "Madrid Bravos": madrid,
    "Istanbul Rams": istanbul,
}

function Logo(props: { teamName: string, gray: boolean, float: string | null, height: string }) {
    let style: { [name: string]: string } = { filter: "" };
    let logo = nameToImg[props.teamName]
    if (props.gray) {
        style["filter"] = "grayscale(100%)";
        if (props.teamName === "Las Vegas Raiders") {
            logo = raiders2;
        }
        if (props.teamName === "Paris Musketeers") {
            logo = paris2;
        }
    }
    if (props.float !== null) {
        style["float"] = props.float;
    }
    return (
        <img style={style} src={logo} alt="Logo" height={props.height} />
    )
}
Logo.defaultProps = { float: null, height: "100px" };

export default Logo;