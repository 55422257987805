import { useState } from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { apiBase } from "../App";

export default function LoginComponent(props: { afterLogin: any }) {
  const [userName, setUserName] = useState();
  const [error, setError] = useState(false);
  const [pw, setPw] = useState();
  const onUserInput = (event: any) => setUserName(event.target.value);
  const onPwInput = (event: any) => setPw(event.target.value);
  const history = useHistory();
  return (
    <div>
      <Row><Col>
        {!error ? "" : (
          <Alert variant="danger" onClose={() => setError(false)} dismissible>
            <Alert.Heading>Fehler!</Alert.Heading>
            <p>
              Überprüfe Benutzername und Passwort und versuch es nochmal
        </p>
          </Alert>
        )}
      </Col> </Row>
      <Row>
        <Col>
          <h3 className="mb-3">Login</h3>
        </Col>
      </Row>
      <Row>
        <Col />
        <Col>
          <Form onSubmit={(event) => {
            event.preventDefault();
            fetch(apiBase + "/auth/login", {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ username: userName, password: pw }) // body data type must match "Content-Type" header
            }).then(
              response => {
                if (!response.ok) {
                  setError(true);
                  return;
                }
                response.json().then(
                  r => {
                    if (r.access_token) {
                      localStorage.setItem('user', JSON.stringify({ user: userName, token: r.access_token }));
                      props.afterLogin();
                      history.push("/");
                    }
                  }
                )
              }
            )
          }}>
            <Form.Group controlId="formBasicUser">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" placeholder="Enter username" onChange={onUserInput} />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" onChange={onPwInput} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
          </Button>
          </Form>
        </Col>
        <Col />
      </Row>
    </div>
  )
}