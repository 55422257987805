import { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { apiBase } from "../App";

export default function ChangePWComponent(props: { user: { token: string } }) {
  const [pw, setPw] = useState("");
  const [pwConfirm, setPwConfirm] = useState("");
  const onPwInput = (event: any) => setPw(event.target.value);
  const onPwConfirmInput = (event: any) => setPwConfirm(event.target.value);
  return (
    <Row>
      <Col />
      <Col>
        <Form onSubmit={(event) => {
          event.preventDefault();
          if (pw !== pwConfirm) {
            alert("Passwords don't match!");
            return;
          }
          fetch(apiBase + "/user/change_password", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + props.user.token
            },
            body: JSON.stringify({ new: pw })
          }).then(
            response => response.json().then(
              r => console.log(r)
            ),
            error => console.log(error)
          )
        }}>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={onPwInput} />
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control type="password" placeholder="Password" onChange={onPwConfirmInput} />
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Col>
      <Col />
    </Row>
  )
}
