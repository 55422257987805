import { range, uniq } from "lodash";
import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { apiBase } from "../App";
import { Game, SurvivorByPlayerAndWeek } from "../util/types";
import { renamedTeams } from "./GamePanel";
import Logo from "./Logo";
import wolf from '../img/wolf.png';
import esel from '../img/esel.png';
import { useViewport } from "../util/Viewport";

export default function ComparisonComponent(props: { season: number, games: Array<Game>, gameDay: number, user: { name: string, token: string }, survivorByPlayerAndWeek: SurvivorByPlayerAndWeek }) {
  const { width } = useViewport();
  const [bets, setBets]: [Array<{ id: number, betHomeWins: boolean, user: { id: number, userName: string }, game: { id: number, season: number, gameDay: number, awayScore: number | null, homeScore: number | null } }>, any] = useState([]);
  useEffect(() => {
    fetch(`${apiBase}/bets/${props.season}/day/${props.gameDay}`, {
      headers: {
        'Authorization': 'Bearer ' + props.user.token
      }
    })
      .then(res => res.json())
      .then(
        (result) => {
          setBets(result.bets);
        },
        (error) => {
          console.log(error);
        }
      )
  }, [props])

  let userNames: Array<string> = [];
  let betMap: { [gameId: number]: { [userName: string]: boolean } } = {}
  if (typeof bets !== "undefined") {
    userNames = uniq(bets.map(x => x.user.userName))
    for (let bet of bets) {
      const gameId = bet.game.id;
      const userName = bet.user.userName;
      if (!(gameId in betMap)) {
        betMap[gameId] = {};
      }
      betMap[gameId][userName] = bet.betHomeWins;
    }
  }
  console.log(betMap)
  console.log(props.games)

  const getTableRow = (g: Game) => {
    let looser = "";
    if (typeof g.homeScore === "number" && typeof g.awayScore === "number") {
      if (g.homeScore > g.awayScore) {
        looser = g.awayTeam.teamName
      }
      if (g.homeScore < g.awayScore) {
        looser = g.homeTeam.teamName
      }
    }
    let homeTeam = g.homeTeam.teamName;
    if (homeTeam in renamedTeams) {
      homeTeam = renamedTeams[homeTeam]
    }
    let awayTeam = g.awayTeam.teamName;
    if (awayTeam in renamedTeams) {
      awayTeam = renamedTeams[awayTeam]
    }
    if (homeTeam === "Vienna Vikings") {
      homeTeam = "Vienna Vikings (alternate)"
    }
    if (awayTeam === "Vienna Vikings") {
      awayTeam = "Vienna Vikings (alternate)"
    }
    let isDonkey = false;
    if (looser && betMap[g.id]) {
      isDonkey = Object.values(betMap[g.id]).filter(x => x === (looser === g.homeTeam.teamName)).length === 1;
    }
    let isLonely = false;
    if (looser && betMap[g.id]) {
      isLonely = Object.values(betMap[g.id]).filter(x => x === (looser !== g.homeTeam.teamName)).length === 1;
    }
    return userNames.map(
      x => {
        return (
          <td>
            {betMap[g.id] && typeof betMap[g.id][x] !== "undefined" ?
              (
                betMap[g.id][x] ?
                  <Logo gray={g.homeTeam.teamName === looser} teamName={homeTeam} height={Math.min(100, Math.floor(width / 5 / 2.5)) + "px"}></Logo> :
                  <Logo gray={g.awayTeam.teamName === looser} teamName={awayTeam} height={Math.min(100, Math.floor(width / 5 / 2.5)) + "px"}></Logo>
              ) :
              ""
            }
            {(isLonely && ((g.homeTeam.teamName !== looser) === betMap[g.id][x])) ? <img src={wolf} height="50px" alt="lonely wolf" style={{ position: "relative" }} /> : ""}
            {(isDonkey && ((g.homeTeam.teamName === looser) === betMap[g.id][x])) ? <img src={esel} height="50px" alt="lonely donkey" style={{ position: "relative" }} /> : ""}
          </td>
        )
      }
    )
  }

  const userNamesSurvivor = Object.keys(props.survivorByPlayerAndWeek)
  const weeks = userNamesSurvivor.length > 0 ? props.survivorByPlayerAndWeek[userNamesSurvivor[0]].results.length : 0
  const isIncorrect = (user: string, week: number) => {
    const result = props.survivorByPlayerAndWeek[user].results[week]
    return result.gameOver && result.alive && !result.correct
  }
  const getLogoForPlayerWeek = (user: string, week: number) => {
    const result = props.survivorByPlayerAndWeek[user].results[week]
    const team = result.team.teamName === "Vienna Vikings" ? "Vienna Vikings (alternate)" : result.team.teamName
    return team ? <Logo teamName={team} gray={result.gameOver && !result.correct} height={"30em"}></Logo> : ""
  }

  return (
    <Container>
      <h3>Pick'em</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <td></td>{userNames.map(x => <td key={x}>{x}</td>)}
          </tr>
        </thead>
        <tbody>
          {props.games.map(g =>
            <tr key={g.id}>
              <td>{g.awayTeam.teamName + " @ " + g.homeTeam.teamName}</td>
              {getTableRow(g)}
            </tr>
          )}
        </tbody>
      </Table>
      <h3>Survivor</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <td></td>{range(weeks).map(x => <td key={x}>{x+1}</td>)}
          </tr>
        </thead>
        <tbody>
          {userNamesSurvivor.map(user =>
            <tr key={user}>
              <td>{user}</td>
              {range(weeks).map(wk => <td key={`${user}_${wk}`} className={isIncorrect(user,wk) ? "crossed" : ""}>
                {getLogoForPlayerWeek(user, wk)}
              </td>)}
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  )
}
