import { Col, Row } from 'react-bootstrap';
import Logo from './Logo';
import atsign from '../img/at.svg';
import '../App.css'

export const teamColors: { [name: string]: string } = {
    "Arizona Cardinals": "#97233F",
    "Atlanta Falcons": "#A71930",
    "Baltimore Ravens": "#241773",
    "Buffalo Bills": "#00338D",
    "Carolina Panthers": "#0085CA",
    "Chicago Bears": "#0B162A",
    "Cincinnati Bengals": "#FB4F14",
    "Cleveland Browns": "#311D00",
    "Dallas Cowboys": "#869397",
    "Denver Broncos": "#FB4F14",
    "Detroit Lions": "#0076B6",
    "Green Bay Packers": "#203731",
    "Houston Texans": "#03202F",
    "Indianapolis Colts": "#002C5F",
    "Jacksonville Jaguars": "#006778",
    "Kansas City Chiefs": "#E31837",
    "Las Vegas Raiders": "#000000",
    "Los Angeles Chargers": "#0080C6",
    "Los Angeles Rams": "#003594",
    "Miami Dolphins": "#008E97",
    "Minnesota Vikings": "#4F2683",
    "New England Patriots": "#002244",
    "New Orleans Saints": "#D3BC8D",
    "New York Giants": "#0B2265",
    "New York Jets": "#125740",
    "Philadelphia Eagles": "#004C54",
    "Pittsburgh Steelers": "#FFB612",
    "San Francisco 49ers": "#AA0000",
    "Seattle Seahawks": "#002244",
    "Tampa Bay Buccaneers": "#D50A0A",
    "Tennessee Titans": "#0C2340",
    "Washington": "#773141",
    "Washington Commanders": "#773141",
    "Barcelona Dragons": "#154630",
    "Berlin Thunder": "#D3BC8D",
    "Cologne Centurions": "#BF1515",
    "Fehérvár Enthroners": "#2F2447",
    "Frankfurt Galaxy": "#000000",
    "Hamburg Sea Devils": "#0D192A",
    "Helvetic Mercenaries": "#9C9496",
    "Leipzig Kings": "#00452A",
    "Milano Seamen": "#02132D",
    "Munich Ravens": "#000000",
    "Panthers Wrocław": "#00AEEF",
    "Paris Musketeers": "#A2AAAD",
    "Prague Lions": "#C41731",
    "Raiders Tirol": "#000000",
    "Rhein Fire": "#66201E",
    "Stuttgart Surge": "#03242C",
    "Vienna Vikings": "#4D2383",
    "Istanbul Rams": "#AA1E3D",
    "Madrid Bravos": "#DD8819",
    "Nordic Storm": "#152E46",
}

export const teamColorsSecondary: { [name: string]: string } = {
    "Arizona Cardinals": "#000000",
    "Atlanta Falcons": "#000000",
    "Baltimore Ravens": "#9E7C0C",
    "Buffalo Bills": "#C60C30",
    "Carolina Panthers": "#101820",
    "Chicago Bears": "#C83803",
    "Cincinnati Bengals": "#000000",
    "Cleveland Browns": "#FF3C00",
    "Dallas Cowboys": "#041E42",
    "Denver Broncos": "#002244",
    "Detroit Lions": "#B0B7BC",
    "Green Bay Packers": "#FFB612",
    "Houston Texans": "#A71930",
    "Indianapolis Colts": "#A2AAAD",
    "Jacksonville Jaguars": "#9F792C",
    "Kansas City Chiefs": "#FFB81C",
    "Las Vegas Raiders": "#A5ACAF",
    "Los Angeles Chargers": "#FFC20E",
    "Los Angeles Rams": "#FFD100",
    "Miami Dolphins": "#FC4C02",
    "Minnesota Vikings": "#FFC62F",
    "New England Patriots": "#C60C30",
    "New Orleans Saints": "#101820",
    "New York Giants": "#A5ACAF",
    "New York Jets": "#000000",
    "Philadelphia Eagles": "#A5ACAF",
    "Pittsburgh Steelers": "#101820",
    "San Francisco 49ers": "#B3995D",
    "Seattle Seahawks": "#69BE28",
    "Tampa Bay Buccaneers": "#696158",
    "Tennessee Titans": "#4B92DB",
    "Washington": "#FFB612",
    "Washington Commanders": "#FFB612",
    "Barcelona Dragons": "#F0DA36",
    "Berlin Thunder": "#000000",
    "Cologne Centurions": "#000000",
    "Fehérvár Enthroners": "#D1D5DB",
    "Frankfurt Galaxy": "#88724D",
    "Hamburg Sea Devils": "#EA2229",
    "Helvetic Mercenaries": "#000000",
    "Leipzig Kings": "#FBB040",
    "Milano Seamen": "#ADFF2F",
    "Munich Ravens": "#1CA5CF",
    "Panthers Wrocław": "#000000",
    "Paris Musketeers": "#FFFAFA",
    "Prague Lions": "#002244",
    "Raiders Tirol": "#000000",
    "Rhein Fire": "#BF9000",
    "Stuttgart Surge": "#D0DF2C",
    "Vienna Vikings": "#FFD500",
    "Istanbul Rams": "#C6C6C6",
    "Madrid Bravos": "#000000",
    "Nordic Storm": "#D1D5DB",
}

export const isAusnahme = (teamA: string, teamB: string) => {
    if (teamA === "Minnesota Vikings" && teamB === "Baltimore Ravens") {
        return true;
    }
    if (teamB === "Minnesota Vikings" && teamA === "Baltimore Ravens") {
        return true;
    }
    if (teamA === "Los Angeles Rams" || teamA === "Indianapolis Colts") {
        if (teamColorsFamily[teamB] === "hellblau") {
            return true;
        }
    }
    if (teamB === "Los Angeles Rams" || teamB === "Indianapolis Colts") {
        if (teamColorsFamily[teamA] === "hellblau") {
            return true;
        }
    }
    return false;
}

export const getBrightness = (colorHex: string) => {
    return (parseInt(colorHex[1] + colorHex[2], 16) + parseInt(colorHex[3] + colorHex[4], 16) + parseInt(colorHex[5] + colorHex[6], 16)) / 3
}

export const teamColorsFamily: { [name: string]: string } = {
    "Arizona Cardinals": "rot",
    "Atlanta Falcons": "rot",
    "Baltimore Ravens": "dunkel",
    "Buffalo Bills": "dunkel",
    "Carolina Panthers": "hellblau",
    "Chicago Bears": "dunkel",
    "Cincinnati Bengals": "orange",
    "Cleveland Browns": "dunkel",
    "Dallas Cowboys": "silber",
    "Denver Broncos": "orange",
    "Detroit Lions": "hellblau",
    "Green Bay Packers": "gruen",
    "Houston Texans": "dunkel",
    "Indianapolis Colts": "dunkel",
    "Jacksonville Jaguars": "gruen",
    "Kansas City Chiefs": "rot",
    "Las Vegas Raiders": "dunkel",
    "Los Angeles Chargers": "hellblau",
    "Los Angeles Rams": "dunkel",
    "Miami Dolphins": "gruen",
    "Minnesota Vikings": "lila",
    "New England Patriots": "dunkel",
    "New Orleans Saints": "gold",
    "New York Giants": "dunkel",
    "New York Jets": "gruen",
    "Philadelphia Eagles": "gruen",
    "Pittsburgh Steelers": "gelb",
    "San Francisco 49ers": "rot",
    "Seattle Seahawks": "dunkel",
    "Tampa Bay Buccaneers": "rot",
    "Tennessee Titans": "dunkel",
    "Washington": "rot",
    "Washington Commanders": "rot",
    "Barcelona Dragons": "gruen",
    "Berlin Thunder": "gold",
    "Cologne Centurions": "rot",
    "Fehérvár Enthroners": "dunkel",
    "Frankfurt Galaxy": "dunkel",
    "Hamburg Sea Devils": "dunkel",
    "Helvetic Mercenaries": "grau",
    "Leipzig Kings": "gruen",
    "Milano Seamen": "dunkel",
    "Munich Ravens": "dunkel",
    "Panthers Wrocław": "hellblau",
    "Paris Musketeers": "grau",
    "Prague Lions": "rot",
    "Raiders Tirol": "dunkel",
    "Rhein Fire": "rot",
    "Stuttgart Surge": "dunkel",
    "Vienna Vikings": "lila",
    "Istanbul Rams": "rot",
    "Madrid Bravos": "rot",
    "Nordic Storm": "dunkel",
}

export const renamedTeams: { [name: string]: string } = {
    "St. Louis Rams": "Los Angeles Rams",
    "San Diego Chargers": "Los Angeles Chargers",
    "Oakland Raiders": "Las Vegas Raiders",
    "Washington": "Washington Commanders",
    "Helvetic Guards": "Helvetic Mercenaries",
}

export const grayColor = "#e0e0e0";

function GamePanel(props: {
    gameId: number,
    homeTeam: string,
    awayTeam: string,
    homeScore: null | number,
    awayScore: null | number,
    homeTeamRecord: Array<number> | undefined,
    awayTeamRecord: Array<number> | undefined,
    betHomeWins: boolean | undefined,
    smallScreen: boolean,
    survivorPick: boolean,
    homeSurvivorUsed: boolean | undefined,
    awaySurvivorUsed: boolean | undefined,
    makeBet: (gameId: number, betHomeWins: boolean, survivorPick: boolean) => void
}) {
    let { homeTeam, awayTeam, homeScore, awayScore, betHomeWins } = props;
    if (homeTeam in renamedTeams) {
        homeTeam = renamedTeams[homeTeam];
    }
    if (awayTeam in renamedTeams) {
        awayTeam = renamedTeams[awayTeam];
    }
    let homeColor = teamColors[homeTeam];
    // special special case
    if (homeTeam === "Cleveland Browns" && awayTeam === "Chicago Bears") {
        homeColor = teamColorsSecondary[homeTeam];
    }
    if (awayTeam === "Raiders Tirol" && teamColorsFamily[homeTeam] === teamColorsFamily[awayTeam]) {
        homeColor = teamColorsSecondary[homeTeam];
    }
    let awayColor = teamColors[awayTeam];
    if (teamColorsFamily[homeTeam] === teamColorsFamily[awayTeam] || isAusnahme(homeTeam, awayTeam)) {
        awayColor = teamColorsSecondary[awayTeam];
    }

    let homeGray = false;
    let awayGray = false;
    if (typeof betHomeWins !== "undefined") {
        if (betHomeWins) {
            awayGray = true;
            awayColor = grayColor;
        } else {
            homeGray = true;
            homeColor = grayColor;
        }
    }
    if (props.survivorPick) {
        if (props.homeSurvivorUsed) {
            homeGray = true;
            homeColor = grayColor;
        }
        if (props.awaySurvivorUsed) {
            awayGray = true;
            awayColor = grayColor;
        }
    }

    if (typeof awayColor === "undefined") { console.log(awayTeam); }
    if (typeof homeColor === "undefined") { console.log(homeTeam); }
    let awayFontColor = getBrightness(awayColor) > 127 ? "black" : "white";
    let homeFontColor = getBrightness(homeColor) > 127 ? "black" : "white";
    let awayBackground = getBrightness(awayColor) > 64 ? "light" : "dark";
    let homeBackground = getBrightness(homeColor) > 64 ? "light" : "dark";

    let border = { borderStyle: "solid", borderColor: "black", borderWidth: "3px", borderRadius: "10px" }
    if (typeof betHomeWins !== "undefined" && typeof awayScore === "number" && typeof homeScore === "number") {
        if ((betHomeWins && homeScore >= awayScore) || (!betHomeWins && awayScore >= homeScore)) {
            border.borderColor = "green"
        } else {
            border.borderColor = "red"
        }
        border.borderWidth = "7px"
    }

    let homeTeamRecord = "0-0-0"
    if (typeof props.homeTeamRecord !== "undefined") {
        homeTeamRecord = props.homeTeamRecord.join("-")
    }
    let awayTeamRecord = "0-0-0"
    if (typeof props.awayTeamRecord !== "undefined") {
        awayTeamRecord = props.awayTeamRecord.join("-")
    }

    const smallScreenHeight = "75px"

    return (
        <Row style={{ ...border, alignItems: "stretch", marginTop: "5px" }}>
            <Col className={`GamePanel ${awayBackground}`} onClick={() => props.makeBet(props.gameId, false, props.survivorPick)} style={{
                float: "left",
                backgroundColor: awayColor,
                color: awayFontColor,
                fontSize: "22px",
                marginRight: props.smallScreen ? "" : "-15px",
                clipPath: props.smallScreen ? "" : "polygon(0 0, 100% 0%, 95% 100%, 0% 100%)"
            }}>
                <Row>
                    <Col md="4"><Logo
                        gray={awayGray}
                        teamName={awayTeam}
                        //float={props.smallScreen ? "left" : null}
                        height={props.smallScreen ? smallScreenHeight : "100px"}

                    /></Col>
                    {props.smallScreen ?
                        (<Col style={{ fontSize: 18, display: "flex", justifyContent: "center", alignItems: "center" }}>{awayTeamRecord}</Col>) :
                        (<Col style={{ alignSelf: "center" }}>{props.awayTeam} <br />{awayTeamRecord}</Col>)}
                    {props.smallScreen ? null : (<Col md="2"></Col>)}
                </Row>
            </Col>
            <Col className={`GamePanel ${homeBackground}`} onClick={() => props.makeBet(props.gameId, true, props.survivorPick)} style={{
                float: "right",
                backgroundColor: homeColor,
                marginLeft: props.smallScreen ? "" : "-15px",
                color: homeFontColor,
                fontSize: "22px",
                clipPath: props.smallScreen ? "" : "polygon(5% 0, 100% 0%, 100% 100%, 0% 100%)"
            }}>
                <Row>
                    {props.smallScreen ? null : <Col md="2"></Col>}
                    {props.smallScreen ?
                        "" :
                        (<Col style={{ alignSelf: "center" }}> {props.homeTeam}<br />{homeTeamRecord}</Col>)
                    }
                    <Col md="4"><Logo
                        gray={homeGray}
                        teamName={homeTeam}
                        //float={props.smallScreen ? "right" : null}
                        height={props.smallScreen ? smallScreenHeight : "100px"}
                    /></Col>
                    {props.smallScreen ?
                        (<Col style={{ fontSize: 18, display: "flex", justifyContent: "center", alignItems: "center" }}>{homeTeamRecord}</Col>) :
                        ""}
                </Row>
            </Col>
            <div
                style={{
                    backgroundColor: "transparent",
                    fontSize: props.smallScreen ? "28px" : "43px",
                    color: "white",
                    left: "0",
                    right: "0",
                    height: props.smallScreen ? smallScreenHeight : "100px",
                    display: "grid",
                    placeItems: "center",
                    position: "absolute",
                    pointerEvents: "none"
                }}
            >
                {typeof props.awayScore === "number" ?
                    <div style={{ backgroundColor: "#000000", paddingRight: "8px", paddingLeft: "8px", borderRadius: "8px" }}><b>{`${props.awayScore}-${props.homeScore}`}</b></div> :
                    <img src={atsign} alt="@" height="70px"></img>
                }
            </div>
        </Row>
    )
}

export default GamePanel;