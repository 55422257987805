import { Col, Row, Form, Button } from 'react-bootstrap';
import Logo from './Logo';
import { useState } from "react";
import '../App.css'
import DateTimePicker from 'react-datetime-picker';
import { teamColors, teamColorsSecondary, teamColorsFamily, isAusnahme, getBrightness, renamedTeams } from './GamePanel';

function GamePanel(props: {
    gameId: number,
    gameTime: string,
    homeTeam: string,
    awayTeam: string,
    homeScore: null | number,
    awayScore: null | number,
    homeTeamRecord: Array<number> | undefined,
    awayTeamRecord: Array<number> | undefined,
    betHomeWins: boolean | undefined,
    smallScreen: boolean,
    updateGame: (gameId: number, gameTime: Date, awayScore: number | null, homeScore: number | null) => void
}) {
    let { homeTeam, awayTeam, homeScore, awayScore, betHomeWins } = props;
    const [hs, setHomeScore] = useState(homeScore);
    const [as, setAwayScore] = useState(homeScore);
    const [gameTime, setGameTime] = useState(new Date(props.gameTime));
    const updateHomeScore = (event: any) => setHomeScore(event.target.value);
    const updateAwayScore = (event: any) => setAwayScore(event.target.value);
    const updateGT = (event: any) => setGameTime(event);
    if (homeTeam in renamedTeams) {
        homeTeam = renamedTeams[homeTeam];
    }
    if (awayTeam in renamedTeams) {
        awayTeam = renamedTeams[awayTeam];
    }
    const grayColor = "#e0e0e0";
    let homeColor = teamColors[homeTeam];
    // special special case
    if (homeTeam === "Cleveland Browns" && awayTeam === "Chicago Bears") {
        homeColor = teamColorsSecondary[homeTeam];
    }
    if (awayTeam === "Raiders Tirol" && teamColorsFamily[homeTeam] === teamColorsFamily[awayTeam]) {
        homeColor = teamColorsSecondary[homeTeam];
    }
    let awayColor = teamColors[awayTeam];
    if (teamColorsFamily[homeTeam] === teamColorsFamily[awayTeam] || isAusnahme(homeTeam, awayTeam)) {
        awayColor = teamColorsSecondary[awayTeam];
    }

    let homeGray = false;
    let awayGray = false;
    if (typeof betHomeWins !== "undefined") {
        if (betHomeWins) {
            awayGray = true;
            awayColor = grayColor;
        } else {
            homeGray = true;
            homeColor = grayColor;
        }
    }

    if (typeof awayColor === "undefined") { console.log(awayTeam); }
    if (typeof homeColor === "undefined") { console.log(homeTeam); }
    let awayFontColor = getBrightness(awayColor) > 127 ? "black" : "white";
    let homeFontColor = getBrightness(homeColor) > 127 ? "black" : "white";

    let border = { borderStyle: "solid", borderColor: "black", borderWidth: "3px", borderRadius: "10px" }
    if (typeof betHomeWins !== "undefined" && typeof awayScore === "number" && typeof homeScore === "number") {
        if ((betHomeWins && homeScore >= awayScore) || (!betHomeWins && awayScore >= homeScore)) {
            border.borderColor = "green"
        } else {
            border.borderColor = "red"
        }
        border.borderWidth = "7px"
    }

    let homeTeamRecord = "0-0-0"
    if (typeof props.homeTeamRecord !== "undefined") {
        homeTeamRecord = props.homeTeamRecord.join("-")
    }
    let awayTeamRecord = "0-0-0"
    if (typeof props.awayTeamRecord !== "undefined") {
        awayTeamRecord = props.awayTeamRecord.join("-")
    }

    const smallScreenHeight = "75px"

    return (
        <div>
            <Row>
                <Col>
                    <DateTimePicker
                        onChange={updateGT}
                        value={gameTime}
                    />
                </Col>
            </Row>
            <Row style={{ ...border, alignItems: "stretch", marginTop: "5px" }}>
                <Col className="GamePanel" style={{
                    float: "left",
                    backgroundColor: awayColor,
                    color: awayFontColor,
                    fontSize: "24px",
                    marginRight: props.smallScreen ? "" : "-15px",
                    clipPath: props.smallScreen ? "" : "polygon(0 0, 100% 0%, 95% 100%, 0% 100%)"
                }}>
                    <Row>
                        <Col md="4"><Logo
                            gray={awayGray}
                            teamName={awayTeam}
                            float={props.smallScreen ? "left" : null}
                            height={props.smallScreen ? smallScreenHeight : "100px"}
                        /></Col>
                        {props.smallScreen ? "" : (<Col style={{ alignSelf: "center" }}>
                            {props.awayTeam} <br />{awayTeamRecord}</Col>)}
                        <Col md="2"></Col>
                    </Row>
                    <Row>
                        <Form.Control placeholder={"" + awayScore} type="text" onChange={updateAwayScore} />
                    </Row>
                </Col>
                <Col className="GamePanel" style={{
                    float: "right",
                    backgroundColor: homeColor,
                    marginLeft: props.smallScreen ? "" : "-15px",
                    color: homeFontColor,
                    fontSize: "24px",
                    clipPath: props.smallScreen ? "" : "polygon(5% 0, 100% 0%, 100% 100%, 0% 100%)"
                }}>
                    <Row>
                        <Col md="2"></Col>
                        {props.smallScreen ? "" : (<Col style={{ alignSelf: "center" }}>
                            {props.homeTeam}<br />{homeTeamRecord}
                        </Col>)}
                        <Col md="4"><Logo
                            gray={homeGray}
                            teamName={homeTeam}
                            float={props.smallScreen ? "right" : null}
                            height={props.smallScreen ? smallScreenHeight : "100px"}
                        /></Col>
                    </Row>
                    <Row>
                        <Form.Control placeholder={"" + homeScore} type="text" onChange={updateHomeScore} />
                    </Row>
                </Col>
            </Row>
            <Row>
                <Button variant="primary" type="submit" onClick={(event) => {
                    event.preventDefault();
                    props.updateGame(props.gameId, gameTime, as, hs);
                }}>
                    Submit
                </Button>
            </Row>
        </div>
    )
}

export default GamePanel;