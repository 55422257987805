import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { apiBase } from "../App";
import { Bet, League } from "../util/types";
import { tidy, groupBy, filter, summarize, sum, transmute, pivotLonger, arrange, desc, cumsum, mutateWithSummary } from '@tidyjs/tidy';
import Plot from 'react-plotly.js';
import { Data } from "plotly.js";
import { useViewport } from "../util/Viewport";

export default function StatsComponent(props: { season: number, league: League }) {
  const [allBets, setAllBets] = useState<Bet[]>([]);
  const [leagueBets, setLeagueBets] = useState<Bet[]>([]);
  const { width } = useViewport();
  useEffect(() => {
    fetch(`${apiBase}/bets/${props.season}/all`)
      .then(res => res.json())
      .then(
        (result) => {
          setAllBets(result.bets);
          setLeagueBets(result.bets.filter((x: Bet) => x.game.league === props.league));
        },
        (error) => {
          console.log(error);
        }
      )
  }, [props.season])
  useEffect(() => {
    setLeagueBets(allBets.filter(x => x.game.league === props.league));
  }, [props.league])
  const tidyBets = tidy(leagueBets,
    filter(b => b.game.homeScore !== null && b.game.awayScore !== null),
    transmute({
      gameDay: b => b.game.gameDay,
      betHomeWins: b => b.betHomeWins,
      userName: b => b.user.userName,
      homeTeam: b => b.game.homeTeam.teamName,
      awayTeam: b => b.game.awayTeam.teamName,
      homeScore: b => b.game.homeScore,
      awayScore: b => b.game.awayScore,
      //@ts-ignore
      winner: b => b.game.homeScore === b.game.awayScore ? 2 : (b.game.homeScore > b.game.awayScore ? 0 : 1),
      //@ts-ignore
      correct: b => b.betHomeWins ? b.game.homeScore >= b.game.awayScore : b.game.homeScore <= b.game.awayScore,
    })
  )
  let betsByWeek = tidy(
    tidyBets,
    arrange('gameDay'),
    groupBy(['gameDay', 'userName'], [summarize({ score: sum('correct') })]),
    groupBy(['userName'], [mutateWithSummary({
      cumscore: cumsum('score')
    })])
  )
  let byWeekData = ['Markus', 'Tobias', 'Moritz', 'Sebastian'].map(
    (user: string): Data => {
      let userData = betsByWeek.filter(x => x.userName === user)
      return {
        type: 'scatter',
        x: userData.map(x => x.gameDay),
        y: userData.map(x => x.score),
        name: user,
      }
    }
  )
  let byWeekCumData = ['Markus', 'Tobias', 'Moritz', 'Sebastian'].map(
    (user: string): Data => {
      let userData = betsByWeek.filter(x => x.userName === user)
      return {
        type: 'scatter',
        x: userData.map(x => x.gameDay),
        y: userData.map(x => x.cumscore),
        name: user,
      }
    }
  )

  let userTeamCombinations = tidy(
    tidyBets,
    pivotLonger({ cols: ["homeTeam", "awayTeam"], namesTo: "where", valuesTo: "team" })
  )

  userTeamCombinations = tidy(userTeamCombinations,
    groupBy(['team', 'userName'], [summarize({ cor: sum('correct') })]),
    arrange(desc('cor'))
  )

  let teamComboData = ['Markus', 'Tobias', 'Moritz', 'Sebastian'].map(
    (user: string): Data => {
      let userData = userTeamCombinations.filter(x => x.userName === user)
      return {
        type: 'bar',
        x: userData.map(x => x.team),
        y: userData.map(x => x.cor),
        name: user,
      }
    }
  )
  return (
    <Container>
      <Plot data={byWeekData}
        layout={{ width: Math.min(width, 900), height: 600, title: 'Points by Week' }}
      />
      <Plot data={byWeekCumData}
        layout={{ width: Math.min(width, 900), height: 600, title: 'Total Score by Week' }}
      />
      <Plot data={teamComboData}
        layout={{ width: Math.min(width, 900), height: 600, title: 'Correct by Team' }}
      />
    </Container>
  )
}
